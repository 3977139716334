import {
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  updateProfile,
  User,
} from 'firebase/auth';
import React, {
  createContext, useContext, useEffect, useMemo, useState,
} from 'react';
import { auth, googleProvider } from '../../.services';

const defaultContext = {
  currentUser: null,
};
const AuthContext = createContext<any>(defaultContext);

function useAuth() {
  return useContext(AuthContext);
}

function AuthProvider({ children }: { children: any }) {
  const [currentUser, setCurrentUser] = useState<User | null>();
  const [currentUserClaims, setCurrentUserClaims] = useState<any | null>();
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  function signup(email: string, password: string) {
    return createUserWithEmailAndPassword(auth, email, password);
  }

  function login(email: string, password: string) {
    return signInWithEmailAndPassword(auth, email, password).then(() => {
      setAuthenticated(true)
    });
  }

  function loginWithGoogle() {
    return signInWithPopup(auth, googleProvider);
  }

  function logout() {
    return signOut(auth).then(() => {
      setAuthenticated(false)
    });
  }

  function passwordReset(email: string) {
    return sendPasswordResetEmail(auth, email);
  }

  function updateDisplayName(displayName: string) {
    if (!currentUser) return;
    return updateProfile(currentUser, {
      displayName: displayName
    });
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user: any) => {
      setCurrentUser(user);
      if (user) {
        setAuthenticated(true);
      }
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  auth.onAuthStateChanged((user: any) => {
    setCurrentUser(user);
    if (user) {
      setAuthenticated(true);
    }
  });

  // Get Current User's Custom Claims
  useEffect(() => {
    if (!currentUser) { 
      return;
    }

    currentUser.getIdTokenResult().then((idTokenResult: any) => {
      setCurrentUserClaims(idTokenResult?.claims || null);
    }).catch((error: any) => {
      console.error(error);
    });
  }, [currentUser]);

  const value = 
  useMemo(
    () => (
      {
      currentUser,
      currentUserClaims,
      login,
      logout,
      passwordReset,
      signup,
      loginWithGoogle,
      updateDisplayName,
      authenticated,
    }
  ),
    [currentUser, currentUserClaims],
  );

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}

export {
  AuthProvider, useAuth,
};
