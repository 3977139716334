import { initializeApp } from 'firebase/app';
import {
  getAuth,
  GoogleAuthProvider,
  onAuthStateChanged,
} from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';

const firebaseConfig = {
  apiKey: process.env.GATSBY_MEMBERSHIP_FIREBASE_API_KEY,
  authDomain: process.env.GATSBY_MEMBERSHIP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.GATSBY_MEMBERSHIP_FIREBASE_DATABASE_URL,
  projectId: process.env.GATSBY_MEMBERSHIP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.GATSBY_MEMBERSHIP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.GATSBY_MEMBERSHIP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.GATSBY_MEMBERSHIP_FIREBASE_APP_ID,
  measurementId: process.env.GATSBY_MEMBERSHIP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const functions = getFunctions(app);
const db = getFirestore(app);
const googleProvider = new GoogleAuthProvider();

export {
  auth, db, functions, httpsCallable, googleProvider, onAuthStateChanged,
};
