import { navigate } from 'gatsby';
import React from 'react';
import { useAuth } from './AuthContext';

type PrivateRouteProps = {
  component: any
  referringPath: string
  location: Location
  path: string
  default?: boolean
  rest?: any
}

const PrivateRoute = ({ component: Component, location, referringPath, ...rest }: PrivateRouteProps) => {
  const {authenticated} = useAuth();
  if (!authenticated) {
    navigate('/login',{ state: { referrer: referringPath } });
    return null;
  }

  return <Component {...rest} />
}

export default PrivateRoute;