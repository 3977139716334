import { Link } from 'gatsby';
import React from 'react';
import { useAuth } from './index';
import { UserIcon } from '@heroicons/react/20/solid';
import { SITE_PAGES } from '../../.services';

function UserAvatar({ location } : any) {
  const { currentUser } = useAuth();

  let details;

  if (!currentUser) {
    details = (
      <div className="flex flex-col w-full">
        <Link
            className="flex items-center justify-center w-[29px] h-[29px] md:w-[37.25px] md:h-[37.25px] bg-darkGreyBlue rounded-full hover:bg-blueLagoon"
            state={{ prevPath: location?.pathname }}
            to={SITE_PAGES.Login.url}
          >
          <UserIcon className="size-4 md:size-6" fill="white"/>
        </Link>
      </div>
    );
  } else {
    details = (
      <Link
        to={SITE_PAGES.MyAccount.url}
        className="flex items-center text-black capitalize gap-x-2"
      >
        <div className="flex justify-center items-center text-white rounded-full bg-darkGreyBlue hover:bg-blueLagoon font-bold w-[29px] h-[29px] md:h-[37.25px] md:w-[37.25px] text-center">
          <p className="block">
            {currentUser.displayName ? currentUser.displayName[0] : 'A'}
          </p>
        </div>
      </Link>
    );
  }

  return <div className="mx-2">{details}</div>;
}

export default UserAvatar;
